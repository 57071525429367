/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';

// MUI
import {
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';

// STORES
import { useStoreShared } from 'store/shared-store';
import { useStoreAuth } from '../../../store/auth-store';

// CONSTANTS
import COLORS from 'constants/COLORS';

// SERVICES
import { enqueueSnackbar } from 'notistack';
import CustomAraSupportIcon from '../Icons/CustomAraSupportIcon';
import { UserHubspotVisitorTokenAPI } from 'services/user-api-services';

export default function HubspotButton() {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    isHubspotChatLoadedStore,
    setIsHubspotChatLoadedStore,
    hubspotChatAccessToken,
    setHubspotChatAccessToken,
  } = useStoreShared();

  const { userProfileStore } = useStoreAuth();

  const handleGetHubspotAuthToken = async () => {
    setIsLoading(true);
    const [status, token] = await UserHubspotVisitorTokenAPI();
    setIsLoading(false);

    if (!status) return;

    setHubspotChatAccessToken(token);
    window.hsConversationsSettings = {
      identificationEmail: userProfileStore.email,
      identificationToken: token,
    };
  };

  React.useEffect(() => {
    if (hubspotChatAccessToken != null) return;
    if (!userProfileStore) return;

    handleGetHubspotAuthToken();
  }, [userProfileStore]);

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  const handleHubspotButton = () => {
    const IS_PROD_ENVIRONMENT = process.env?.REACT_APP_ENV != null && process.env?.REACT_APP_ENV === 'production';

    if (!IS_PROD_ENVIRONMENT) {
      enqueueSnackbar('Hubspot Live Chat is only enabled for production env', { variant: 'info' });
      return;
    }

    if (!hubspotChatAccessToken) return;

    const status = window.HubSpotConversations.widget.status();

    if (status?.loaded) {
      window.HubSpotConversations.widget.remove();
      setIsHubspotChatLoadedStore(false);
    } else {
      window.HubSpotConversations.widget.load({ widgetOpen: true });
      window.HubSpotConversations.widget.open();
      setIsHubspotChatLoadedStore(true);
    }
  };

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  return isSmScreen ? (
    <IconButton
      color="primary"
      edge="start"
      disableFocusRipple
      disableRipple
      data-cy="hubspot-btn"
      sx={
        {
          backgroundColor: COLORS.ara0,
          border: '1px solid #E0E3E7',
          borderRadius: '20px',
          ml: 2,
        }
      }
      onClick={handleHubspotButton}
    >
      {isHubspotChatLoadedStore ? <Close color="primary" fontSize="small" /> : <CustomAraSupportIcon fontSize="small" />}
    </IconButton>
  )
    : (
      <LoadingButton
        sx={{
          ml: 2,
          backgroundColor: COLORS.ara0,
          color: theme.palette.primary.main,
        }}
        size="small"
        loading={isLoading}
        color="araSecondary"
        startIcon={isHubspotChatLoadedStore ? <Close /> : <CustomAraSupportIcon />}
        variant="contained"
        onClick={handleHubspotButton}
      >
        Ask Ara
      </LoadingButton>
    );
}
