import { SvgIcon } from '@mui/material';
import React from 'react';
/* eslint-disable max-len */
export default function CustomAraSupportIcon(props) {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99998 1.5C4.8645 1.5 1.5 4.8645 1.5 9V12.1073C1.5 12.8753 2.17275 13.5 2.99999 13.5H3.75C3.94891 13.5 4.13967 13.421 4.28033 13.2803C4.42098 13.1397 4.5 12.9489 4.5 12.75V8.89274C4.5 8.69383 4.42098 8.50307 4.28033 8.36242C4.13967 8.22177 3.94891 8.14275 3.75 8.14275H3.069C3.48599 5.24025 5.98349 2.99999 8.99998 2.99999C12.0165 2.99999 14.514 5.24025 14.931 8.14275H14.25C14.051 8.14275 13.8603 8.22177 13.7197 8.36242C13.579 8.50307 13.5 8.69383 13.5 8.89274V13.5C13.5 14.3272 12.8272 15 12 15H10.5V14.25H7.49999V16.5H12C13.6545 16.5 15 15.1545 15 13.5C15.8273 13.5 16.5 12.8753 16.5 12.1073V9C16.5 4.8645 13.1355 1.5 8.99998 1.5Z" />
    </SvgIcon>
  );
}
