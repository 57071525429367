// Updated on 18-Sep-2024
const CONFIG = {
  API: {
    development: {
      ADMIN_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/admin',
      AUTH_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/auth',
      FILE_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/file',
      INVOICE_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/invoice',
      LOD_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/lod',
      MAGIC_LINK_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/magiclink',
      NOTIFICATION_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/notification',
      PAYMENT_PLAN_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/paymentplan',
      PAYMENT_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/payment',
      PDF_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/pdf',
      PRICING_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/pricing',
      RECORD_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/record',
      USER_SERVICE_URL: 'https://dev2.ara-pay.com/development/ara/user',
    },
    staging: {
      ADMIN_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/admin',
      AUTH_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/auth',
      FILE_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/file',
      INVOICE_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/invoice',
      LOD_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/lod',
      MAGIC_LINK_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/magiclink',
      NOTIFICATION_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/notification',
      PAYMENT_PLAN_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/paymentplan',
      PAYMENT_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/payment',
      PDF_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/pdf',
      PRICING_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/pricing',
      RECORD_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/record',
      USER_SERVICE_URL: 'https://dev2.ara-pay.com/staging/ara/user',
    },
    production: {
      ADMIN_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/admin',
      AUTH_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/auth',
      FILE_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/file',
      INVOICE_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/invoice',
      LOD_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/lod',
      MAGIC_LINK_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/magiclink',
      NOTIFICATION_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/notification',
      PAYMENT_PLAN_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/paymentplan',
      PAYMENT_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/payment',
      PDF_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/pdf',
      PRICING_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/pricing',
      RECORD_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/record',
      USER_SERVICE_URL: 'https://apiv2.ara-pay.com/ara/user',
    },
  },

};

export default CONFIG;
